import Axios from "axios";
import { bodyHeader, $observable } from "../../common/libs/AxiosObservable";

export const getDomains = () => {
  return $observable(Axios.get("api/v1/admin/domains"));
};

export const getDomain = (id) => {
  return $observable(Axios.get(`api/v1/admin/domains/${id}`));
};

export const deleteDomain = (id) => {
  return $observable(Axios.delete(`api/v1/admin/domains/${id}`));
};

export const addDomain = (obj) => {
  return $observable(
    Axios.post(`api/v1/admin/domains`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyDomain = (obj) => {
  return $observable(
    Axios.patch(`api/v1/admin/domains`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
