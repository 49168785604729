import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

export default function data({ model, deleteEvent, editEvent }) {
  model = model ?? [];

  const Overall = ({ name, id, code, ip }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <div>
        <button code={"btnEdit" + id} onClick={() => editEvent(`${id}`)}
          style={{ border: 0, backgroundColor: 'transparent' }}>
          <MDBadge
            badgeContent="Sửa"
            color="success"
            variant="gradient"
            size="sm"
          />
        </button>
        <div style={{ height: 4 }} />
        <button
          style={{ border: 0, backgroundColor: 'transparent' }}
          code={"btnDelete" + id}
          onClick={() => {
            deleteEvent(`${id}`);
          }}
        >
          <MDBadge
            badgeContent="Xóa"
            color="warning"
            variant="gradient"
            size="sm"
          />
        </button>
      </div>
      <MDBox ml={2} lineHeight={1} style={{ flexDirection: 'column', display: 'flex' }}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{code}</MDTypography>
        <MDTypography variant="caption">IP: {ip}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const RowItem = ({ value }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption">{value}</MDTypography>
    </MDBox>
  );

  const rows = [];

  model.forEach((item) => {
    rows.push({
      overall: <Overall name={item.name} id={item.id ?? 0} code={item.code ?? 0} ip={item.ip ?? 0} />,
      description: <RowItem value={item.description} />,
      branch: <RowItem value={item.branch} />,
      specification: <RowItem value={item.specification} />,
      configuration: (
        <RowItem
          value={`${item.core} CPU - ${item.ram} GB - ${item.storage} GB`}
        />
      ),
    });
  });

  return {
    columns: [
      { Header: "Mục", accessor: "overall", width: "45%", align: "left" },
      { Header: "Mô tả", accessor: "description", align: "left" },
      { Header: "Hãng sản phẩm", accessor: "branch", align: "left" },
      { Header: "Thông số kỹ thuật", accessor: "specification", align: "left" },
      { Header: "Cấu hình", accessor: "configuration", align: "left" },
    ],
    rows: rows,
  };
}
