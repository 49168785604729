import { styles } from './styles';
import FormHelperText from "@mui/material/FormHelperText";

export function ComboBox({ value, data, onItemChange, placeHolder, error, errorMessage, ignorePlaceholder }) {
    var optionRows = ignorePlaceholder ? [] : [<option value={"none"}>{placeHolder}</option>];
    if (data != null) {
        data.forEach((item) => {
            optionRows.push(
                <option key={item.id} value={item.id}>{item.name}</option>
            );
        });
    }
    return (<>
        <select
            style={{ ...styles.textNormal }}
            id="cat-combobox"
            defaultValue={value}
            value={value}
            className="form-select"
            onChange={($this) => {
                var value = $this.target.value;
                onItemChange(value)
            }}>
            {optionRows}
        </select>
        {error && <FormHelperText error id={`outlined-cat-combobox-error`}>
            {errorMessage}
        </FormHelperText>}
    </>
    );
}