import Axios from "axios";
import {
  bodyHeader,
  $observable,
} from "common/libs/AxiosObservable";

export const getServers = () => {
  return $observable(Axios.get("api/v1/admin/servers"));
};

export const getServersByNetwork = (id) => {
  if (!id || id === "none") {
    return getServers();
  }
  return $observable(Axios.get(`api/v1/admin/servers?$filter=networkID eq ${id}`));
};

export const getServer = (id) => {
  return $observable(Axios.get(`api/v1/admin/servers/${id}`));
};

export const getVMList = (id, excludeId) => {
  return $observable(Axios.get(`api/v1/admin/servers/${id}/free-vms?excludeId=${excludeId}`));
};

export const deleteServer = (id) => {
  return $observable(Axios.delete(`api/v1/admin/servers/${id}`));
};

export const addServer = (obj) => {
  return $observable(
    Axios.post(`api/v1/admin/servers`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyServer = (obj) => {
  return $observable(
    Axios.patch(`api/v1/admin/servers`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
