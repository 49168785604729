export const SERVICE_TYPES = [
  {
    id: 'vps',
    name: 'VPS'
  },
  {
    id: 'domain',
    name: 'Domain'
  }
];

export const PAYMENT_CYCLES = [
  {
    id: '3months',
    name: '3 tháng'
  },
  {
    id: '6months',
    name: '6 tháng'
  },
  {
    id: '1year',
    name: '1 năm'
  },
  {
    id: '3years',
    name: '3 năm'
  }
  ,
  {
    id: '5years',
    name: '5 năm'
  }
];