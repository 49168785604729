import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { modifyDomain, addDomain } from "../../domain/apis/domainService";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormUIItem } from '../commons';
import { styles } from "./styles";
import Row from "react-bootstrap/Row";
import FormControl from "@mui/material/FormControl";
import Col from "react-bootstrap/Col";
import { ComboBox } from "../commons/Combobox";
import { PAYMENT_CYCLES } from 'common/Constants';
import DatePicker from "react-datepicker";

function DomainModifyComponent({ show, handleClose, data }) {
  const [formUI, setFormUI] = useState({});
  const [error, setError] = useState({});
  const [expiryDate, setExpiryDate] = useState();

  useEffect(() => {
    setError({});
  }, [show]);

  useEffect(() => {
    if (!data) {
      setFormUI({});
      setExpiryDate(null);
    } else {
      setFormUI(data);
      setExpiryDate(data.expiry ? new Date(data.expiry) : null);
    }
  }, [data, show]);

  const handleUpdateForm = (key, value) => {
    switch (key) {
      case 'price':
        value = Number(value);
        break;
      default:
        break;
    }
    let obj = {};
    obj[key] = value;
    setFormUI({
      ...formUI,
      ...obj,
    })
  };

  const validateForm = () => {
    var result = true;
    var errorState = {};

    if (!formUI["name"] || formUI["name"] === '') {
      result = false;
      errorState.name = true;
    }

    setError(errorState);
    return result;
  }

  const onModify = () => {
    if (!validateForm()) {
      return;
    }

    if (data) {
      modifyDomain(formUI).subscribe({
        next: ({ data }) => {
          handleClose(true);
        },
      });
      return;
    }
    addDomain(formUI).subscribe({
      next: ({ data }) => {
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {data && <Modal.Title>Chỉnh sửa Domain</Modal.Title>}
            {!data && <Modal.Title>Thêm Domain</Modal.Title>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Row>
                  <Col
                    className="col-6 col-md-4"
                    style={{ ...styles.selfCenter, ...styles.textNormal }}
                  >
                    Chu kỳ thanh toán*
                  </Col>
                  <Col className="col-12 col-sm-6 col-md-8">
                    <ComboBox
                      ignorePlaceholder
                      value={formUI["paymentCycle"] ?? "1year"}
                      error={_.get(error, "paymentCycle")}
                      errorMessage={"Vui lòng chọn nội dung!"}
                      data={PAYMENT_CYCLES}
                      placeHolder={"--- Chọn dịch vụ ---"}
                      onItemChange={(value) => {
                        handleUpdateForm("paymentCycle", value);
                      }}
                    />
                  </Col>
                </Row>
              </FormControl>
              <FormUIItem
                label={"Tên"}
                code={"name"}
                required
                error={_.get(error, "name")}
                errorMessage={"Vui lòng nhập nội dung!"}
                initValue={_.get(data, "name")}
                handleUpdate={handleUpdateForm} />

              <FormUIItem
                label={"Đơn giá"}
                code={"price"}
                unit={"VNĐ"}
                type={"number"}
                initValue={_.get(data, "price") ?? 0}
                handleUpdate={handleUpdateForm}
              />
              <Row style={{ marginLeft: 8, display: 'inline-block', marginBottom: 4 }}>
                <span
                  style={{ ...styles.selfCenter, ...styles.textNormal, marginLeft: -12 }}
                >
                  Ngày hết hạn
                </span>
                <DatePicker
                  onChange={(date) => {
                    setExpiryDate(date);
                    handleUpdateForm('expiry', date)
                  }}
                  dateFormat="dd/MM/yyyy"
                  selected={expiryDate} />
              </Row>
              <FormUIItem
                label={"Mô tả"}
                code={"description"}
                initValue={_.get(data, "description")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Cloudflare ID"}
                code={"CloudflareID"}
                initValue={_.get(data, "CloudflareID")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Cloudflare Token"}
                code={"cloudflareToken"}
                initValue={_.get(data, "cloudflareID")}
                handleUpdate={handleUpdateForm} />
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DomainModifyComponent;
