import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/react-datepicker.css";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "./context";
import { initDefault } from "common/libs/AxiosDefaultConfig";
import { setDefaultLocale } from "react-datepicker";
import vi from 'date-fns/locale/vi';
setDefaultLocale(vi);

initDefault();

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
