// Material Dashboard 2 React layouts
import Dashboard from "./layouts/dashboard";
import VPS from "./layouts/vps";
import Domains from "./layouts/domains";
import Servers from "./layouts/servers";
import Networks from "layouts/networks";
import SignIn from "./layouts/authentication/sign-in";
// import SignUp from "./layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Contracts from "layouts/contracts";
import Customers from "layouts/customers";
import Organizations from "layouts/organizations";
import Packages from "layouts/packages";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Hợp đồng",
    key: "contracts",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/contracts",
    component: <Contracts />,
  },
  {
    type: "collapse",
    name: "Khách hàng",
    key: "customers",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/customers",
    component: <Customers />,
  },
  {
    type: "collapse",
    name: "Tổ chức",
    key: "organizations",
    icon: <Icon fontSize="small">business</Icon>,
    route: "/organizations",
    component: <Organizations />,
  },
  {
    type: "collapse",
    name: "Domain",
    key: "domains",
    icon: <Icon fontSize="small">public</Icon>,
    route: "/domains",
    component: <Domains />,
  },
  {
    type: "collapse",
    name: "VPS",
    key: "vps",
    icon: <Icon fontSize="small">computer</Icon>,
    route: "/vps",
    component: <VPS />,
  },
  {
    type: "collapse",
    name: "Máy Chủ Server",
    key: "servers",
    icon: <Icon fontSize="small">storage</Icon>,
    route: "/servers",
    component: <Servers />,
  },
  {
    type: "collapse",
    name: "Mạng Network",
    key: "networks",
    icon: <Icon fontSize="small">lan</Icon>,
    route: "/networks",
    component: <Networks />,
  },
  {
    type: "collapse",
    name: "Gói Dịch Vụ",
    key: "packages",
    icon: <Icon fontSize="small">class</Icon>,
    route: "/packages",
    component: <Packages />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    hide: true,
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

export default routes;
