import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";

const FormUIItem = ({
    code,
    label,
    initValue,
    value,
    disabled,
    required,
    type,
    unit,
    error,
    errorMessage,
    handleUpdate }) => {
    if (!code || !label) {
        return <></>;
    }
    return (<><div style={{ height: unit ? 4 : 0 }} />
        <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel required={required === true} htmlFor={`outlined-${code}`}>
                {label}
            </InputLabel>
            <OutlinedInput
                disabled={disabled}
                error={error}
                defaultValue={initValue}
                value={value}
                onChange={({ target }) => {
                    let { value } = target;
                    handleUpdate(code, value);
                }}
                startAdornment={
                    unit && <InputAdornment position="start">{unit}</InputAdornment>
                }
                type={type}
                required={required === true}
                id={`outlined-${code}`}
                label={label}
            />
            {
                error && <FormHelperText error id={`outlined-${code}-error`}>
                    {errorMessage}
                </FormHelperText>
            }
        </FormControl>
    </>);
}

export default FormUIItem;