import Axios from "axios";
import {
  bodyHeader,
  $observable,
} from "common/libs/AxiosObservable";

export const getCustomers = () => {
  return $observable(Axios.get("api/v1/admin/customers"));
};

export const getCustomer = (id) => {
  return $observable(Axios.get(`api/v1/admin/customers/${id}`));
};

export const getCustomerByOrganization = (id) => {
  if (!id || id === "none") {
    return getCustomers();
  }
  return $observable(Axios.get(`api/v1/admin/customers?$filter=organizationID eq ${id}`));
};

export const deleteCustomer = (id) => {
  return $observable(Axios.delete(`api/v1/admin/customers/${id}`));
};

export const addCustomer = (obj) => {
  return $observable(
    Axios.post(`api/v1/admin/customers`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyCustomer = (obj) => {
  return $observable(
    Axios.patch(`api/v1/admin/customers`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
