import Axios from "axios";
import {
  bodyHeader,
  $observable,
} from "../../common/libs/AxiosObservable";

export const getPackages = () => {
  return $observable(Axios.get("api/v1/admin/packages"));
};

export const getPackageByType = (type) => {
  if (!type || type === "none") {
    return getPackages();
  }
  return $observable(Axios.get(`api/v1/admin/packages?$filter=contains(type,'${type}')`));
};

export const getPackage = (id) => {
  return $observable(Axios.get(`api/v1/admin/packages/${id}`));
};

export const deletePackage = (id) => {
  return $observable(Axios.delete(`api/v1/admin/packages/${id}`));
};

export const addPackage = (obj) => {
  return $observable(
    Axios.post(`api/v1/admin/packages`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyPackage = (obj) => {
  return $observable(
    Axios.patch(`api/v1/admin/packages`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
