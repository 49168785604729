import Axios from "axios";
import {
  bodyHeader,
  $observable,
} from "../../common/libs/AxiosObservable";

export const getContracts = () => {
  return $observable(Axios.get("api/v1/admin/contracts"));
};

export const getContract = (id) => {
  return $observable(Axios.get(`api/v1/admin/contracts/${id}`));
};

export const deleteContract = (id) => {
  return $observable(Axios.delete(`api/v1/admin/contracts/${id}`));
};

export const addContract = (obj) => {
  return $observable(
    Axios.post(`api/v1/admin/contracts`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyContract = (obj) => {
  return $observable(
    Axios.patch(`api/v1/admin/contracts`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
