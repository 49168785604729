import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { modifyContract, addContract } from "../../domain/apis/contractService";
import { getCustomers } from "../../domain/apis/customerService";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormUIItem } from '../commons';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function ContractModifyComponent({ show, handleClose, data }) {
  const [formData, setFormData] = useState({});
  const [customerName, setCustomerName] = useState(null);
  const [customers, setCustomers] = useState(null);

  useEffect(() => {
    getCustomers().subscribe({
      next({ data }) {
        const { value } = data;
        setCustomers(value.map(customer => `${customer.fullName} - ${customer.email} - ${customer.id}`));
      }
    })
  }, []);

  useEffect(() => {
    if (data) {
      setFormData(data);
    } else {
      setFormData({});
      setCustomerName(null);
    }
  }, [data]);

  const handleUpdateForm = (key, value) => {
    setFormData(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const onModify = () => {
    if (data) {
      modifyContract(formData).subscribe({
        next: ({ data }) => {
          handleClose(true);
        },
      });
      return;
    }
    addContract(formData).subscribe({
      next: ({ data }) => {
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Modal.Title>{data ? "Sửa hợp đồng" : "Thêm hợp đồng"}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormUIItem
                label={"Tên"}
                code={"name"}
                required
                initValue={_.get(data, "name")}
                handleUpdate={handleUpdateForm} />
              <Autocomplete
                options={customers}
                value={customerName}
                style={{
                  paddingRight: "8px",
                  paddingLeft: "8px",
                  width: "100%",
                }}
                onChange={(event, newValue) => {
                  let arr = newValue?.split('-') ?? [];
                  if (arr.length > 1) {
                    handleUpdateForm('customerID', arr[2].trim());
                  }
                  setCustomerName(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Khách hàng"
                    placeholder="Nhập để tìm kiếm..."
                  />
                )}
                sx={{ width: 300, margin: "20px auto" }}
                freeSolo
                autoSelect
              />
              <FormUIItem
                label={"Mô tả"}
                code={"description"}
                initValue={_.get(data, "description")}
                handleUpdate={handleUpdateForm} />
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContractModifyComponent;
